import { roles } from 'src/app/models/role';

const AllRoles = Object.values(roles);

export const menuItemsArray = [
  {
    id: 'products',
    titleKey: 'products_menu',
    roles: AllRoles,
    submenu: [
      {
        titleKey: 'submenu_help',
        items: [
          {
            titleKey: 'submenu_generate_portfolio',
            link: '/portfolio'
          }
        ]
      },
      {
        titleKey: 'submenu_platform_features',
        items: [
          {
            titleKey: 'submenu_set_alerts',
            link: '/account/alerts'
          }
        ]
      },
      {
        titleKey: 'submenu_personal_area',
        items: [
          {
            titleKey: 'submenu_my_ideas',
            link: '/ideas'
          },
          {
            titleKey: 'submenu_my_alerts',
            link: '/account/alerts'
          },
          {
            titleKey: 'submenu_my_screenings',
            link: '/my-screenings'
          },
          {
            titleKey: 'submenu_my_portfolios',
            link: '/portfolio'
          }
        ]
      }
    ]
  },
  {
    id: 'resources',
    titleKey: 'resources_menu',
    roles: AllRoles,
    link: '/resources/articles'
  },
  {
    id: 'why-mg',
    titleKey: 'menu_why_mg',
    link: '/why-mg'
  }
];

export const profileSubmenuItems = (profile) => [
  {
    id: 'profile-help',
    titleKey: 'submenu_profile_help',
    items: profile
      ? [
          {
            titleKey: 'submenu_change_pass',
            link: null
          },
          {
            titleKey: 'submenu_send_invitation',
            link: '/invite'
          },
          {
            titleKey: 'submenu_contact_support',
            link: '/contact-support'
          }
        ]
      : [
          {
            titleKey: 'submenu_forgot_pass',
            link: '/account/recover-password',
            click: null
          },
          {
            titleKey: 'submenu_get_invitation',
            link: '/invitation-request'
          },
          {
            titleKey: 'submenu_contact_support',
            link: '/contact-support'
          }
        ]
  },
  {
    id: 'admin',
    titleKey: 'menu_admin',
    roles: [roles.Administrator],
    color: '#AB7AD1',
    items: [
      {
        titleKey: 'menu_accounts',
        link: '/admin#users'
      },
      {
        titleKey: 'menu_subscription',
        link: '/products#free'
      },
      {
        titleKey: 'menu_system',
        link: '/system-settings'
      },
      {
        titleKey: 'menu_notifications',
        link: '/notifications-and-polls#notifications'
      },
      {
        titleKey: 'menu_resources',
        link: '/resources/editor'
      }
    ]
  },
  {
    id: 'admin',
    titleKey: 'menu_admin',
    roles: [roles.Corporate],
    color: '#AB7AD1',
    items: [
      {
        titleKey: 'menu_accounts',
        link: '/admin#users'
      }
    ]
  },
  {
    id: 'profile-personal',
    titleKey: profile ? 'submenu_logout' : 'submenu_login',
    items: profile
      ? [
          {
            title: profile.email
          },
          {
            titleKey: 'title_profile',
            link: '/account'
          },
          {
            titleKey: 'my_subscriptions_title',
            link: '/account/subscription'
          },
          {
            titleKey: 'logout'
          }
        ]
      : [
          {
            key: 'signin'
          }
        ]
  }
];
