import React, { useState, useMemo, useRef } from 'react';

import { Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';

import { appMessage } from '../message';
import { typeMessage } from '../message/constants';
import { searchCompanyByNameAsync } from 'src/app/api/company';
import { SearchInput } from 'src/app/components/form/search-select';

import styles from './SearchCompany.module.css';

export const SearchCompany = ({ value, onChange, selectStyle, ...rest }) => {
  const dispatch = useDispatch();

  const id = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);

  const fetchRef = useRef(0);

  const [fetching, setFetching] = useState(false);
  const [searchText, setSearchText] = useState();
  const [options, setOptions] = useState([]);

  const handleSearch = useMemo(() => {
    const loadOptions = (compName) => {
      if (compName) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;

        setFetching(true);
        setOptions([]);
        dispatch(searchCompanyByNameAsync(compName))
          .then((data) => {
            if (fetchId !== fetchRef.current) {
              return;
            }

            setSearchText(compName);
            setOptions(
              data.map((c, i) => {
                return {
                  value: c.cik,
                  label: c.company_name,
                  tickerLabel: c.ticker
                };
              })
            );
            setFetching(false);
          })
          .catch((e) => {
            appMessage(typeMessage.ERROR, e.message);
          });
      } else {
        setOptions([]);
      }
    };

    return debounce(loadOptions, 200);
  }, [dispatch]);

  return (
    <div id={`select-wrapper-${id}`} className={styles.selectWrapper}>
      <SearchInput
        optionLabelProp="label"
        data-test="home_page_search_company"
        placeholder={'Search company'}
        value={value}
        options={options}
        onChange={onChange}
        onSearch={handleSearch}
        style={selectStyle}
        getPopupContainer={() => document.getElementById(`select-wrapper-${id}`)}
        notFoundContent={
          fetching ? (
            <div className={styles.spinnerWrapper}>
              <Spin size="small" />
            </div>
          ) : (
            ''
          )
        }
        customOptionRender={(option) => (
          <div className={styles.optionWrapper}>
            <div
              className={styles.tickerColumn}
              dangerouslySetInnerHTML={{
                __html: option.tickerLabel.replaceAll(new RegExp(searchText, 'gi'), `<strong>$&</strong>`)
              }}
            ></div>
            <div
              className={styles.companyColumn}
              dangerouslySetInnerHTML={{
                __html: option.label.replaceAll(new RegExp(searchText, 'gi'), `<strong>$&</strong>`)
              }}
            ></div>
          </div>
        )}
        {...rest}
      />
    </div>
  );
};
